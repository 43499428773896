import React, { Component } from 'react';
import Select from 'react-select';
import 'react-toastify/dist/ReactToastify.css';
import { Grid, Typography, FormControlLabel, Switch, Button, Divider, Checkbox } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import * as Sentry from '@sentry/react';
import { toast } from 'react-toastify';

import TaboolaBids from './TaboolaBids';
import TaboolaCreativeGroupBuilder from './TaboolaCreativeGroupBuilder';
import { getSuggestedBids, getTaboolaSubAccounts, storeFiles } from '../Api';
import { getPlatformSites } from '../Sites';
import { GLOBAL, PLATFORMS, TASKS, TB, TB_OS } from '../Constants';
import { parseCampaignName } from '../NameParser';
import WidgetSelector from '../WidgetSelector';
import { taboolaCCStore, taboolaConversionRulesStore } from '../Stores';
import CampaignsNote from '../CampaignsNote';
import {
  displayWarnings,
  getImageDimensions,
  truncateFileName,
  validateAndPrepareImage
} from '../utils/imageUploadUtils';
import Prefix from '../Prefix';
import { normalizeCreative } from '../utils/creativesUtils';
import {
  articleCodeLanguageVersion,
  getCreationDate,
  getFileHash,
  getMultiSelectValue,
  getNewCreatorValue,
  getOnlyValue,
  getSelectValue,
  makeSelectOptions,
  parseBulkData,
  validateCreativeGroups
} from '../Shared';
import _ from 'lodash';

const DEVICES = [TB.DESKTOP, TB.MOBILE, TB.TABLET];

class TaboolaCreate extends Component {
  constructor(props) {
    super(props);

    const defaultOptions = {
      [TB.KEY_BUDGET]: TB.DEFAULT_BUDGET,
      [TB.KEY_DEFAULT_BID]: TB.DEFAULT_BID,
      [TB.KEY_CREATIVE_GROUPS]: [],
      [TB.KEY_BIDS]: [],
      [TB.KEY_OS_CHOICES]: [TB.IOS, TB.ANDROID, TB.ALL],
      [TB.KEY_OS]: [TB.IOS, TB.ANDROID],
      [TB.KEY_PREFIX]: '',
      [TB.KEY_SITE]: [],
      [TB.KEY_COUNTRY]: ['us'],
      [TB.KEY_DEVICE]: [TB.DESKTOP, TB.MOBILE],
      [TB.KEY_BROWSER]: ['Edge', 'Chrome'],
      [TB.KEY_MARKETING_OBJECTIVE]: 'LEADS_GENERATION',
      [TB.KEY_SPENDING_LIMIT_MODEL]: 'NONE',
      [TB.KEY_BID_TYPE]: 'OPTIMIZED_CONVERSIONS',
      [TB.KEY_BID_STRATEGY]: 'SMART',
      [TB.KEY_GEO_DUPLICATION_TYPE]: 'separate',
      [TB.KEY_OS_DUPLICATION_TYPE]: 'separate',
      [TB.KEY_DEVICE_DUPLICATION_TYPE]: 'separate',
      [GLOBAL.KEY_DESTINATION_ACCOUNT]: '',
      [GLOBAL.KEY_DESTINATION_SUB_ACCOUNT]: this.props.selectedSubAccount || '',
      [TB.KEY_USE_DUPLICATION_API]: false,
      [TB.KEY_CREATOR]: '',
      [TB.KEY_CONVERSION_ID]: '',
      [TB.KEY_STATUS]: 'active',
      [TB.KEY_TIMES]: 1
    };

    this.suggestedBidsTaboola = [];
    this.defaultState = {
      options: {
        ...defaultOptions,
        ...this.props.options,
        prefixes: [],
        prefix: '',
        siteCopies: {}
      },
      triggerSelectorUpdate: false,
      subAccounts: this.props.subAccounts || [],
      browsers: [],
      customAudiences: [],
      tooltips: { text: '', open: false },
      freeText: '',
      conversionRules: [],
      isBulk: this.props.isBulk || false,
      destinationSubAccount: '',
      randomizeAccount: false
    };
    this.isSwag = this.props.programOptions.isSwag;
    this.state = JSON.parse(JSON.stringify(this.defaultState));
  }

  /*
  Signature:
  [{
    images: ['https://pbs.twimg.com/media/Ed8FISLXkAI6wG-.jpg', ...],
    texts: ['a', ...],
    tooltips: [could be empty, not sure where needed],
    titles: [could be empty, not sure where exactly needed],
  }, ...]
  */

  async addCreatives(xs) {
    const groups = this.state.options[TB.KEY_CREATIVE_GROUPS];
    const newGroups = xs.map(() => ({
      images: [],
      texts: [],
      descriptions: [],
      cta: []
    }));

    this.setState(
      { options: { ...this.state.options, [TB.KEY_CREATIVE_GROUPS]: [...groups, ...newGroups] } },
      async () => {
        for (let i = 0; i < xs.length; i++) {
          const x = xs[i];
          const textsAndTitles = (x.texts || []).concat(x.titles || []);
          const descriptions = x.descriptions || [];

          for (const text of textsAndTitles)
            for (const description of descriptions)
              for (const img of x.images) {
                const blob = await fetch(`/api/getImg?img=${encodeURIComponent(img)}`).then(r => r.blob());
                blob.name = img.split('/').pop();

                newGroups[i].texts.push(text);
                newGroups[i].descriptions.push(description);

                this.modifyOptions(TB.KEY_CREATIVE_GROUPS, [...groups, ...newGroups]);
                this.onAddImages(groups.length + i, { target: { files: [blob] } });
              }
        }
      }
    );
  }

  async addCreativesFromFbLibrary(xs) {
    const groups = this.state.options[TB.KEY_CREATIVE_GROUPS];
    const newGroups = xs.map(() => ({
      images: [],
      texts: [],
      descriptions: [],
      cta: []
    }));

    this.setState(
      { options: { ...this.state.options, [TB.KEY_CREATIVE_GROUPS]: [...groups, ...newGroups] } },
      async () => {
        for (let i = 0; i < xs.length; i++) {
          const x = xs[i];
          const textsAndTitles = (x.texts || []).concat(x.titles || []);

          // for (const text of textsAndTitles)
          for (const img of x.images) {
            const blob = await fetch(`/api/getImg?img=${encodeURIComponent(img)}`).then(r => r.blob());
            blob.name = img.split('/').pop();

            newGroups[i].texts.push(textsAndTitles);

            this.modifyOptions(TB.KEY_CREATIVE_GROUPS, [...groups, ...newGroups]);
            this.onAddImages(groups.length + i, { target: { files: [blob] } });
          }
        }
      }
    );
  }

  componentDidMount() {
    getSuggestedBids(PLATFORMS.TABOOLA.toLowerCase()).then(bids => {
      this.suggestedBidsTaboola = bids;
    });

    this.updateDataSegments();
    this.updateUserOptions();
    this.updateCAs();
    this.updateConversionRules();
  }

  updateDataSegments() {
    if (this.props.task !== TB.DUPLICATE_WITH_DATA_SEGMENTS) {
      this._modifyOptions(TB.KEY_METRIC, null);
    } else {
      this._modifyOptions(TB.KEY_METRIC, TB.METRIC_OPTIONS[0]);
    }
  }

  updateCAs() {
    taboolaCCStore.get('shinez-network').then(customAudiences => {
      console.log({ customAudiences });
      this.setState({ customAudiences });
    });
  }

  updateConversionRules() {
    taboolaConversionRulesStore.get('shinez-network').then(conversionRules => {
      const conversionRulesArray = Object.values(conversionRules);
      const activeConversionRules = conversionRulesArray
        .filter(
          rule =>
            rule.display_name.includes('EPC_') ||
            rule.display_name.includes('S2S_') ||
            rule.display_name.includes('S2SR')
        )
        .sort((a, b) => {
          return a.display_name.localeCompare(b.display_name);
        });
      this.setState({ conversionRules: activeConversionRules });
    });
  }

  async updateUserOptions() {
    if (!this.props.loadedFromSnapshot) {
      const userOpts = this.props.programOptions;
      let prefix = userOpts.spm_prefix_for_taboola ? userOpts.spm_prefix_for_taboola : userOpts.spm_prefix_default;
      console.log(prefix);
      this.modifyOptions(TB.KEY_CREATOR, getNewCreatorValue(userOpts.email, this.props.creatorsList));
      this.modifyOptions(TB.KEY_PREFIX, prefix);
    }
  }

  async componentDidUpdate(prevProps) {
    if (
      this.props.platform !== prevProps.platform ||
      this.props.task !== prevProps.task ||
      this.props.account !== prevProps.account
    ) {
      this.setState(JSON.parse(JSON.stringify(this.defaultState)), () => {
        this.updateDataSegments();
        this.updateSubAccounts();
        this.updateUserOptions();
      });
    }
    if (this.props.creativeFromFbLibrary) {
      this.props.creativeFbLibraryDone();
      await this.addCreativesFromFbLibrary(this.props.creativeFromFbLibrary);
    }
    if (this.props.isBulk !== prevProps.isBulk) {
      this.setState({ isBulk: this.props.isBulk });
    }
    if (this.props.task !== prevProps.task) {
      this.setState({ isBulk: false });
    }
    if (this.props.creative) {
      this.props.creativeDone();
      const creativePlatform = PLATFORMS[this.props.creative.platform.toUpperCase()];
      let creator = this.props.creative?.campaignCreator || this.props.creative?.creator;
      let parsedName = {};

      parsedName = parseCampaignName(creativePlatform, this.props.creative.name);
      this.modifyOptions(TB.KEY_COUNTRY, parsedName.country || '');
      this.modifyOptions(TB.KEY_SITE, parsedName.domain || parsedName.site || []);

      setTimeout(
        () =>
          this.autoFillWidget &&
          typeof this.autoFillWidget === 'function' &&
          this.autoFillWidget(parsedName.widgetCodeName, parsedName.widgetLanguageCode, 'shz'),
        121
      );
      setTimeout(() => this.setState({ triggerSelectorUpdate: !this.state.triggerSelectorUpdate }), 221);

      this.modifyOptions('widgetCodeName', parsedName.widgetCodeName || '');
      this.modifyOptions('widgetid', parsedName.widgetCodeName || '');
      this.modifyOptions('widgetLanguageCode', parsedName.widgetLanguageCode || '');
      this.modifyOptions('widgetVersion', 'shz');

      if (parsedName.platform === 'd') {
        this.modifyOptions(TB.KEY_DEVICE, TB.DESKTOP);
      } else if (parsedName.platform === 'a') {
        this.modifyOptions(TB.KEY_DEVICE, [TB.DESKTOP, TB.MOBILE, TB.TABLET].join(','));
      } else if (parsedName.platform === 'm') {
        this.modifyOptions(TB.KEY_DEVICE, TB.MOBILE);
      } else {
        if (parsedName.platform && parsedName.platform.includes('adr')) {
          this.modifyOptions(TB.KEY_OS, 'Android');
          this.modifyOptions(TB.KEY_DEVICE, TB.MOBILE);
        }
        if (parsedName.platform && parsedName.platform.includes('ios')) {
          this.modifyOptions(TB.KEY_OS, 'iOS');
          this.modifyOptions(TB.KEY_DEVICE, TB.MOBILE);
        }
      }
      if (creator) {
        this.modifyOptions(TB.KEY_CREATOR, creator);
      }
      const creative = normalizeCreative(this.props.creative);

      this.addCreatives([
        {
          images: creative.image_url,
          texts: creative.title,
          titles: [],
          tooltips: [],
          descriptions: creative.text,
          cta: []
        }
      ]);
    }

    if (this.props.campaign !== prevProps.campaign) {
      // TODO: maybe unnecessary... or update just options?
      const newState = JSON.parse(JSON.stringify(this.defaultState));
      newState.subAccounts = this.state.subAccounts;
      this.setState(newState, () => this.updateDataSegments());
    }

    if (this.props.sourceData !== prevProps.sourceData) {
      const newOptions = this.getSourceData(
        this.state.options,
        this.props.sourceData,
        this.props.articleList,
        this.props.platform,
        this.props.creatorsList,
        this.props.isBulk
      );
      this.setState(
        {
          options: newOptions,
          isBulk: this.props.isBulk,
          triggerSelectorUpdate: !this.state.triggerSelectorUpdate
        },
        () => {
          this.modifyBidsRows();
        }
      );
    }

    if (this.props.destinationAccount !== prevProps.destinationAccount) {
      this._modifyOptions(GLOBAL.KEY_DESTINATION_ACCOUNT, this.props.destinationAccount);
      this.updateSubAccounts();
    }
  }

  getSourceData(options, sourceData, articleList, platform, creatorsList, isBulk) {
    let parsed;
    if (isBulk) {
      console.log('bulk data');
      parsed = parseBulkData(options, sourceData, articleList, PLATFORMS.TABOOLA);
    } else {
      console.log('source data');
      parsed = parseDuplicateData(options, sourceData, articleList, platform, creatorsList);
    }
    console.log({ parsed });
    return parsed;
  }
  updateSubAccounts() {
    if (this.props.destinationAccount) {
      getTaboolaSubAccounts().then(subAccounts => {
        this.setState({ subAccounts, isBulk: false });
      });
    } else {
      this.setState({ subAccounts: [], isBulk: false });
    }
  }

  getBrandingText(domain) {
    const site = getPlatformSites(PLATFORMS.TABOOLA.toLowerCase()).find(el => el.code === domain);
    if (site) {
      return site.branding;
    } else {
      throw new Error("Can't find site corresponding to 3-letter domain" + domain);
    }
  }

  getFullSite(domain) {
    const site = getPlatformSites(PLATFORMS.TABOOLA.toLowerCase()).find(el => el.code === domain);
    if (site) {
      return site.name;
    }
    return undefined;
  }

  validateOptions(key) {
    if (key === TB.KEY_DEFAULT_BID) {
      const value = this.state.options[key];
      if (value < TB.MIN_BID) {
        console.info('Min bid reached');
        this._modifyOptions(key, TB.MIN_BID);
        return;
      }
      if (value > TB.MAX_BID) {
        console.info('Max bid reached');
        this._modifyOptions(key, TB.MAX_BID);
      }
    }
  }

  _modifyOptions(key, value) {
    const options = this.state.options;
    options[key] = value;
    this.setState({
      options: options
    });
  }

  updateOption(key, value) {
    if (value) {
      this.modifyOptions(key, [value]);
    }
  }
  modifyOptions(key, value) {
    if (key === TB.KEY_CONVERSION_ID) {
      let bids = this.state.options[TB.KEY_BIDS];
      const conversionObj = this.state.conversionRules.find(el => el.id === value);
      bids.forEach(bid => {
        bid.conversionId = value;
        bid.conversionName = conversionObj.display_name;
      });
      this._modifyOptions(TB.KEY_BIDS, bids);
    }
    if (key === TB.KEY_CA) {
      let bids = this.state.options[TB.KEY_BIDS];
      bids.forEach(bid => {
        bid.conversionId = null;
        bid.conversionName = null;
      });
      this._modifyOptions(TB.KEY_BIDS, bids);
    }
    if (key === TB.KEY_DEVICE && typeof value === 'string') {
      value = value.split(',');
    }
    if (key === TB.KEY_PREFIX) {
      if (value.target) {
        value = value.target.value;
      }
    }
    if (key === TB.KEY_BID_STRATEGY && value === 'MAX_CONVERSIONS') {
      this.setDefaultBid(0, value);
    }
    this._modifyOptions(key, value);
    if (key === TB.KEY_DEVICE) {
      let devices = value;
      let newValue = [];
      devices.forEach(dev => {
        if (dev !== TB.DESKTOP) {
          // Only show OSs for non-desktop
          if (TB_OS[dev]) {
            TB_OS[dev].forEach(os => {
              if (newValue.indexOf(os) === -1) {
                newValue.push(os);
              }
            });
          }
        } else {
          this._modifyOptions(TB.KEY_BROWSER, 'Chrome');
        }
      });
      this._modifyOptions(TB.KEY_OS, newValue.join(','));
      this._modifyOptions(TB.KEY_OS_CHOICES, newValue);
    }
    if (
      key === TB.KEY_COUNTRY ||
      key === TB.KEY_DEVICE ||
      key === TB.KEY_OS ||
      key === TB.KEY_SITE ||
      key === TB.KEY_GEO_DUPLICATION_TYPE ||
      key === TB.KEY_BROWSER ||
      key === TB.KEY_OS_DUPLICATION_TYPE ||
      key === TB.KEY_DEVICE_DUPLICATION_TYPE
    ) {
      this.modifyBidsRows();
    }
    if (key === TB.KEY_DEFAULT_BID) {
      this.setDefaultBid(value);
    }
    if (key === TB.KEY_SITE) {
      let newSiteCopies = { ...this.state.options.siteCopies };

      this.state.options[TB.KEY_SITE].forEach(site => {
        if (!Object.prototype.hasOwnProperty.call(newSiteCopies, site)) {
          newSiteCopies[site] = 1;
        }
      });
      this.modifyOptions('siteCopies', newSiteCopies);
    }
  }

  modifyBidsRows() {
    if (
      !this.state.options[TB.KEY_COUNTRY] ||
      !this.state.options[TB.KEY_DEVICE] ||
      (this.state.options[TB.KEY_DEVICE].indexOf(TB.DESKTOP) === -1 && !this.state.options[TB.KEY_OS]) ||
      !this.state.options[TB.KEY_SITE]
    ) {
      return;
    }
    let geos = this.state.options[TB.KEY_COUNTRY];
    if (typeof geos === 'string') {
      geos = geos.split(',');
    }
    if (this.state.options[TB.KEY_GEO_DUPLICATION_TYPE] === 'group') {
      // This geos should be treated like a group
      geos = [geos.join(',')];
    }
    let devices = this.state.options[TB.KEY_DEVICE];
    if (typeof devices === 'string') {
      devices = devices.split(',');
    }
    if (this.state.options[TB.KEY_DEVICE_DUPLICATION_TYPE] === 'group') {
      // This devices should be treated like a group
      devices = [devices.join(',')];
    }
    let oss = this.state.options[TB.KEY_OS];
    if (typeof oss === 'string') {
      oss = oss.split(',');
    }
    if (this.state.options[TB.KEY_OS_DUPLICATION_TYPE] === 'group') {
      // This os should be treated like a group
      oss = [oss.join(',')];
    }
    let sites = this.state.options[TB.KEY_SITE];
    if (typeof sites === 'string') {
      sites = sites.split(',');
    }

    let browsers = this.state.options[TB.KEY_BROWSER];
    if (typeof browsers === 'string') {
      browsers = browsers.split(',');
    }
    let newBids = [];
    let oldBids = this.state.options[TB.KEY_BIDS];
    let cpa = this.state.options[TB.KEY_BID_STRATEGY] === 'MAX_CONVERSIONS';

    geos.forEach(geo => {
      sites.forEach(site => {
        devices.forEach(dev => {
          if (dev === TB.DESKTOP) {
            // special handling of DESKTOP, no OSs here
            newBids.push({
              country: geo,
              device: dev,
              browser: browsers,
              site: site,
              fullSite: this.getFullSite(site),
              branding_text: this.getBrandingText(site),
              bid: cpa ? TB.NO_BID : TB.DEFAULT_DESKTOP_BID,
              conversionId: TB.DEFAULT_DESKTOP_CONVERSION
            });
          } else {
            oss.forEach(os => {
              newBids.push({
                country: geo,
                device: dev,
                os: os,
                site: site,
                fullSite: this.getFullSite(site),
                branding_text: this.getBrandingText(site),
                bid: cpa ? TB.NO_BID : os === TB.IOS ? TB.DEFAULT_IOS_BID : TB.DEFAULT_ANDROID_BID,
                conversionId: os === TB.IOS ? TB.DEFAULT_IOS_CONVERSION : TB.DEFAULT_ANDROID_CONVERSION
              });
            });
          }
        });
      });
    });

    newBids = newBids.map(newBid => {
      const old = oldBids.find(
        el =>
          el.country === newBid.country &&
          el.device === newBid.device &&
          el.os === newBid.os &&
          el.site === newBid.site &&
          el.browser === newBid.browser
      );
      if (old) {
        newBid.bid = old.bid;
      }
      return newBid;
    });

    this._modifyOptions(TB.KEY_BIDS, newBids);
  }

  modifyBid(idx, e) {
    const bid = e.target.value;
    console.info('Modifying bid ', bid);
    let bids = this.state.options[TB.KEY_BIDS];
    bids[idx].bid = bid;
    this._modifyOptions(TB.KEY_BIDS, bids);
    let warningBids = bids.filter(el => {
      return el.bid > TB.MAX_WARN_BID;
    });
    this._modifyOptions('warnings', warningBids);
  }

  modifyConversionRule(idx, e) {
    const id = e;
    console.info('Modifying conversion rule ', id);
    let bids = this.state.options[TB.KEY_BIDS];
    const conversionObj = this.state.conversionRules.find(el => el.id === id);
    bids[idx].conversionId = id ? id : null;
    bids[idx].conversionName = conversionObj ? conversionObj.display_name : null;
    this._modifyOptions(TB.KEY_BIDS, bids);
  }

  validateBid(idx) {
    let bids = this.state.options[TB.KEY_BIDS];
    const bid = bids[idx].bid;
    if (bid < TB.MIN_BID) {
      bids[idx].bid = TB.MIN_BID;
      this._modifyOptions(TB.KEY_BIDS, bids);
    } else if (bid > TB.MAX_BID) {
      bids[idx].bid = TB.MAX_BID;
      this._modifyOptions(TB.KEY_BIDS, bids);
    }
  }

  setDefaultBid(val, strategy) {
    let bids = this.state.options[TB.KEY_BIDS];
    bids.forEach(b => {
      b.bid = val;
      if (strategy !== 'MAX_CONVERSIONS') {
        if (val < TB.MIN_BID) {
          b.bid = TB.MIN_BID;
        } else if (val > TB.MAX_BID) {
          b.bid = TB.MAX_BID;
        }
      }
    });
    this._modifyOptions(TB.KEY_BIDS, bids);
  }

  onAddImages(groupIdx, e) {
    const images = Array.from(e.target.files);
    console.info('Added', images.length);
    const errs = [];
    const warnings = [];

    const formData = new FormData();

    let basicValidatedImages = [];
    let hashes = [];
    return Promise.all(
      images.map(file => {
        return getImageDimensions(file);
      })
    )
      .then(dimensions => {
        console.debug('DIM', dimensions);
        images.forEach((file, i) => {
          console.debug(file);
          console.debug(file.type);
          const filename_truncated = truncateFileName(file.name);

          if (!validateAndPrepareImage(file, filename_truncated, dimensions[i], groupIdx, 'TB', warnings, errs)) {
            return;
          }

          formData.append(i, file);
          basicValidatedImages.push(file);
        });

        return Promise.all(
          basicValidatedImages.map(file => {
            return getFileHash(file);
          })
        );
      })
      .then(calculatedHashes => {
        hashes = calculatedHashes;
        if (displayWarnings(errs, warnings)) return;
        const options = {
          minWidth: TB.MIN_WIDTH,
          minHeight: TB.MIN_HEIGHT
        };
        return storeFiles(formData, options);
      })
      .then(filenames => {
        console.debug('FILENAMES', filenames);
        if (filenames) {
          let groups = this.state.options[TB.KEY_CREATIVE_GROUPS];
          groups[groupIdx].images = groups[groupIdx].images.concat(filenames);
          for (let i = 0; i < filenames.length; i++) {
            groups[groupIdx].cta.push('READ_MORE');
          }
          this.modifyOptions(TB.KEY_CREATIVE_GROUPS, groups);
        }
      })
      .catch(err => {
        console.error(err);
        Sentry.captureException(err);
      });
  }

  onChangeImageText(groupIdx, imageIdx, e) {
    const text = e.target.value;
    let groups = this.state.options[TB.KEY_CREATIVE_GROUPS];
    groups[groupIdx].texts[imageIdx] = text;
    this.modifyOptions(TB.KEY_CREATIVE_GROUPS, groups);
  }

  onChangeImageDescription(groupIdx, imageIdx, e) {
    const description = e.target.value;
    let groups = this.state.options[TB.KEY_CREATIVE_GROUPS];
    groups[groupIdx].descriptions[imageIdx] = description;
    this.modifyOptions(TB.KEY_CREATIVE_GROUPS, groups);
  }

  onChangeImageCTA(groupIdx, imageIdx, e) {
    const cta = e.value;
    let groups = this.state.options[TB.KEY_CREATIVE_GROUPS];
    groups[groupIdx].cta[imageIdx] = cta;
    this.modifyOptions(TB.KEY_CREATIVE_GROUPS, groups);
  }

  onPasteImageText(groupIdx, imageIdx, e) {
    const text = e.clipboardData.getData('Text');
    if (text.length > TB.MAX_TEXT_CHARS) {
      toast.error(`Pasted text too long: ${text.length} chars. Truncating.`);
    }
  }

  removeImage(groupIdx, imageIdx) {
    console.debug('Removing', groupIdx, imageIdx);
    let groups = this.state.options[TB.KEY_CREATIVE_GROUPS];
    groups[groupIdx].images.splice(imageIdx, 1);
    groups[groupIdx].texts.splice(imageIdx, 1);
    groups[groupIdx].descriptions.splice(imageIdx, 1);
    groups[groupIdx].cta.splice(imageIdx, 1);
    this.modifyOptions(TB.KEY_CREATIVE_GROUPS, groups);
  }

  duplicateImage(groupIdx, imageIdx) {
    let groups = this.state.options[TB.KEY_CREATIVE_GROUPS];
    const duplicatedImage = groups[groupIdx].images[imageIdx];
    const duplicatedTitle = groups[groupIdx].descriptions[imageIdx];
    const duplicateText = groups[groupIdx].texts[imageIdx];
    const duplicateCta = groups[groupIdx].cta[imageIdx];
    groups[groupIdx].images.push(duplicatedImage);
    groups[groupIdx].descriptions.push(duplicatedTitle);
    groups[groupIdx].texts.push(duplicateText);
    groups[groupIdx].cta.push(duplicateCta);
    this.modifyOptions(TB.KEY_CREATIVE_GROUPS, groups);
  }

  removeGroup(groupIdx) {
    console.debug('Removing group', groupIdx);
    let groups = this.state.options[TB.KEY_CREATIVE_GROUPS];
    groups.splice(groupIdx, 1);
    this.modifyOptions(TB.KEY_CREATIVE_GROUPS, groups);
  }

  duplicateGroup(groupIdx) {
    console.debug('Duplicating group', groupIdx);
    let groups = this.state.options[TB.KEY_CREATIVE_GROUPS];
    // Deep copy group
    groups.push(JSON.parse(JSON.stringify(groups[groupIdx])));
    this.modifyOptions(TB.KEY_CREATIVE_GROUPS, groups);
  }

  populateText(groupIdx, imageIdx) {
    let groups = this.state.options[TB.KEY_CREATIVE_GROUPS];
    console.debug('Filling', groupIdx, imageIdx);
    console.debug('Len', groups[groupIdx].images.length);
    groups[groupIdx].images.forEach((_, idx) => {
      console.debug('Setting text ', idx);
      groups[groupIdx].texts[idx] = groups[groupIdx].texts[imageIdx];
      groups[groupIdx].descriptions[idx] = groups[groupIdx].descriptions[imageIdx] || '';
    });
    this.modifyOptions(TB.KEY_CREATIVE_GROUPS, groups);
  }

  addCreativeGroup() {
    let groups = this.state.options[TB.KEY_CREATIVE_GROUPS] || [];
    groups.push({
      images: [],
      texts: [],
      descriptions: [],
      cta: []
    });
    this.modifyOptions(TB.KEY_CREATIVE_GROUPS, groups);
  }

  getDevices() {
    return DEVICES.map(el => ({ label: el, value: el }));
  }

  getFinalDuplicationOptions(options) {
    // check if the field is the same as in original source data

    if (!options[TB.KEY_ORIGINAL_SOURCE_DATA]) {
      return options;
    }

    const isChanged = names =>
      names.some(n => JSON.stringify(options[n]) !== JSON.stringify(options[TB.KEY_ORIGINAL_SOURCE_DATA][n]));

    const areCreativesChanged = isChanged([TB.KEY_CREATIVE_GROUPS]);

    options.campaign_name = areCreativesChanged ? null : options.campaignName;

    return options;
  }

  upsertSnapshot() {
    if (this.props.task === TASKS.CREATE) {
      this.props.upsertSnapshot(this.state.options);
    } else if (this.isDuplicate()) {
      const duplicationOptions = this.getFinalDuplicationOptions(this.state.options);
      this.props.upsertSnapshot(duplicationOptions);
    } else {
      console.error(`Unknown task: ${this.props.task}`);
      throw new Error(`Unknown task: ${this.props.task}`);
    }
  }

  constructCreativeGroups(creativeGroups) {
    return creativeGroups.map(group => ({
      item: group.images.map((image, index) => ({
        image,
        title: group.texts[index],
        text: group.descriptions[index],
        cta: group.cta[index]
      }))
    }));
  }

  prepareOptions(options) {
    const payload = { campaign: [] };
    const duplicationOptions = this.getFinalDuplicationOptions(options);
    if (!this.state.isBulk) {
      payload.campaign.push(this.prepareCreateOptions(duplicationOptions));
    } else {
      const bulkOptions = this.prepareBulkOptions(options);
      payload.campaign.push(...bulkOptions.map(option => this.prepareCreateOptions(option)));
    }
    console.log({ payload });
    if (this.state.destinationSubAccount && this.state.destinationSubAccount.length > 0) {
      let result = {
        account: [
          {
            account_id: this.state.destinationSubAccount,
            campaign: payload.campaign
          }
        ]
      };
      console.log(result);
      return result;
    } else {
      return payload;
    }
  }

  prepareCreateOptions(options) {
    const qs = options.qs ? `&${options.qs}` : '';
    const { isBulk } = this.state;
    let payload = {
      daily_cap: options.daily_cap,
      prefix: options.prefix,
      marketing_objective: options.marketing_objective,
      spending_limit_model: options.spending_limit_model,
      bid_strategy: options.bid_strategy,
      status: options.status === 'active',
      article: isBulk ? options.article : options.fullCodeName,
      metric: options.metric,
      creator: options.campaignCreator,
      free_text: options.freeText || '',
      isBulk: isBulk,
      custom_audience: options.customAudiences ? options.customAudiences : null,
      note: options.campaignNote || '',
      clone_count: options.cloneCount,
      __split: [
        isBulk ? [{ item: options.creativeGroups }] : this.constructCreativeGroups(options.creativeGroups),
        options.bids.map(b => ({
          country: b.country,
          device: b.device,
          os: options.os && options.os[0] !== '' ? options.os : null,
          browser: options.browser,
          site: b.site,
          clone_count: options.siteCopies[b.site],
          bid: b.bid,
          conversion: b.conversionId,
          qs: b.conversionName?.includes('S2S') ? `event=${b.conversionName}${qs}` : qs
        }))
      ]
    };
    if (options.campaign_name || isBulk) {
      if (!this.state.randomizeAccount) {
        payload.duplicate = options.campaignName;
      }
    }
    return payload;
  }

  startCreateCampaign() {
    const options = this.state.options;
    const preparedOptions = this.prepareOptions(options);
    console.log({ preparedOptions });
    this.props.campaignCreate(preparedOptions);
  }

  isDuplicate() {
    return this.props.task === TASKS.DUPLICATE || this.props.task === TB.DUPLICATE_WITH_DATA_SEGMENTS;
  }

  getCampaignsNumber() {
    return this.state.options[TB.KEY_BIDS].length * this.state.options[TB.KEY_CREATIVE_GROUPS].length;
  }

  getCreateButtonText() {
    const campaignsNumber = this.getCampaignsNumber();
    const displayCampaignsNumber = campaignsNumber || '';
    switch (this.props.task) {
      case TASKS.CREATE:
        return `Create ${displayCampaignsNumber} campaigns`;
      case TASKS.DUPLICATE:
      case TB.DUPLICATE_WITH_DATA_SEGMENTS:
        return `Duplicate campaign to ${displayCampaignsNumber} new campaigns`;
      default:
        return 'Something is wrong';
    }
  }

  getAllowedWidgetVersions() {
    // If some selected domain allows only a subset of widget versions, return it instead of all allowed versions
    let sites = this.state.options[TB.KEY_SITE];
    if (typeof sites === 'string') {
      sites = sites.split(',');
    }
    for (const s of sites) {
      if (TB.SITE_ALLOWED_VERSIONS[s]) {
        return TB.SITE_ALLOWED_VERSIONS[s];
      }
    }
    return TB.ALLOWED_VERSIONS;
  }

  getMetricOptions() {
    return TB['METRIC_OPTIONS'].map(x => ({ label: x, value: x }));
  }
  prepareBulkOptions() {
    let options = this.state.options;
    let sourceCampaigns = options[TB.KEY_ORIGINAL_SOURCE_DATA];
    const preparedCampaigns = sourceCampaigns.map(sourceCampaign => ({
      ...options,
      ...sourceCampaign
    }));
    let campaign = [];
    preparedCampaigns.flatMap(camp => {
      campaign.push(this.prepareCreateOptions(camp));
    });
    return preparedCampaigns;
  }

  renderRowName(bid) {
    const parts = [bid.country, bid.device, bid.os, bid.site];
    let res = parts.filter(el => el).join('-') + ' = ' + bid.bid;
    return res;
  }

  isEmptyCreatives(options) {
    return options.campaign.every(camp => {
      const isValid = camp.__split[0]?.length && camp.__split[0].every(item => item && Object.keys(item).length > 0);
      if (!isValid) {
        console.warn(GLOBAL.ERRORS.EMPTY_CREATIVE_GROUPS + ' in campaign: ' + camp.duplicate);
        toast.error(GLOBAL.ERRORS.EMPTY_CREATIVE_GROUPS + ' in campaign: ' + camp.duplicate);
        return;
      }

      return isValid;
    });
  }

  isEmptyArticle(options) {
    return options.campaign.every(camp => {
      const isValid = camp.article;
      if (!isValid) {
        console.warn(GLOBAL.ERRORS.EMPTY_ARTICLE + ' in campaign: ' + camp.duplicate);
        toast.error(GLOBAL.ERRORS.EMPTY_ARTICLE + ' in campaign: ' + camp.duplicate);
        return;
      }

      return isValid;
    });
  }

  isEmptyCreator(options) {
    return options.campaign.every(camp => {
      const isValid = camp.creator;
      if (!isValid) {
        console.warn(GLOBAL.ERRORS.EMPTY_CREATOR + ' in campaign: ' + camp.duplicate);
        toast.error(GLOBAL.ERRORS.EMPTY_CREATOR + ' in campaign: ' + camp.duplicate);
        return;
      }

      return isValid;
    });
  }

  validateSetup() {
    // Check that we have campaigns to create
    if (!this.state.isBulk && !this.getCampaignsNumber()) {
      const err = 'Select all required fields';
      console.warn(err);
      toast.error(err);
      return false;
    }

    if (!this.state.isBulk && !this.state.options.widgetid) {
      const err = 'widget id is empty';
      console.warn(err);
      toast.error(err);
      return false;
    }

    // Check creative group validity
    const validationInfo = validateCreativeGroups(this.state.options[TB.KEY_CREATIVE_GROUPS]);
    if (validationInfo.isValid) {
      console.debug('Creative groups are valid');
    } else {
      console.error('Creative groups are invalid', validationInfo.data);
      validationInfo.data.forEach(err => {
        toast.error(err);
      });
      return false;
    }

    if (this.props.task === TB.DUPLICATE_WITH_DATA_SEGMENTS && !this.state.options[TB.KEY_METRIC]) {
      const err = 'Metric is empty';
      console.error(err);
      toast.error(err);
      return false;
    }

    if (!this.state.options.campaignCreator) {
      const err = 'Creator is empty';
      console.error(err);
      toast.error(err);
      return false;
    }
    if (!this.state.options.prefix) {
      const err = 'Prefix is empty';
      console.error(err);
      toast.error(err);
      return false;
    }
    if (this.state.isBulk && !this.props.sourceData.length) {
      const err = 'Source campaigns are empty';
      console.error(err);
      toast.error(err);
      return false;
    }
    if (this.state.isBulk && !this.state.options[TB.KEY_BIDS].length) {
      const err = 'Select required options';
      console.error(err);
      toast.error(err);
      return false;
    }
    return true;
  }

  constructCampaignName(options) {
    if (!options) {
      return '';
    }
    let oldNaming = {};
    if (options.sourceName) {
      oldNaming = parseCampaignName(PLATFORMS.TABOOLA, options.sourceName);
    }

    const OS_DICT = {
      android: 'adr',
      ios: 'ios',
      all: 'all'
    };
    const parts = [];
    parts.push(options.prefix || oldNaming.prefix);
    let countryPart = oldNaming.country;
    if (options.country) {
      if (options.country.indexOf(',') !== -1) {
        // It's a group geo, set name to ww
        countryPart = 'ww';
      } else {
        countryPart = options.country[0];
      }
    }
    parts.push(countryPart);
    let platform = 'd';
    if (options.device) {
      const devices = options.device.map(item => item.toLowerCase());
      if (devices.includes('desktop') && devices.includes('mobile')) {
        platform = 'a';
      } else if (devices.includes('desktop')) {
        platform = 'd';
      } else if (devices.includes('mobile')) {
        platform = 'm';
      }
    }
    parts.push(platform || oldNaming.platform);
    if (options.site) {
      if (typeof options.site === 'string') {
        options.site = options.site.split(',');
      }
      parts.push(options.site[0] || oldNaming.site);
    }
    if (options.widgetCodeName && options.widgetLanguageCode) {
      parts.push(options.widgetCodeName + options.widgetLanguageCode || oldNaming.article || 'ARTICLE');
    } else {
      parts.push(oldNaming.article || 'ARTICLE');
    }
    if (typeof options.os === 'string') {
      options.os = options.os.split(',');
    }
    if (
      options.device &&
      options.device[0] &&
      options.device[0].toLowerCase() !== 'desktop' &&
      options.os &&
      options.os[0]
    ) {
      parts.push(options.os.length > 1 ? 'all' : OS_DICT[options.os[0].toLowerCase()]);
    }
    parts.push(getCreationDate());

    if (!options.metric) {
      parts.push('c1000');
    }

    const metric_dict = {
      CTR: 'dsc',
      Spent: 'dss'
    };
    if (options.metric) {
      parts.push(metric_dict[options.metric] + '10');
    }
    if (this.state.options.freeText) {
      parts.push(this.state.options.freeText);
    }

    return parts.join('-');
  }

  onChangeFreeText(e) {
    let text = e.target.value;
    this._modifyOptions('freeText', text);
    const newCampName = this.constructCampaignName(this.state.options);
    let tooltips = this.state.options.tooltips;
    const charsLeft = TB.MAX_CAMP_NAME_LENGTH - newCampName.length;
    tooltips = {
      text: `Chars left: ${charsLeft}`,
      open: true
    };
    const nonLetterNumber = /[^a-z0-9]+/i;
    if (text.match(nonLetterNumber)) {
      tooltips.text = 'Only letters and numbers';
      text = text.replace(nonLetterNumber, '');
      this._modifyOptions('freeText', text);
      this._modifyOptions('tooltips', tooltips);
      return;
    }
    if (charsLeft < 0) {
      text = text.slice(0, -1);
      tooltips.text = `Chars left: 0`;
    }
    this._modifyOptions('freeText', text);
    this._modifyOptions('tooltips', tooltips);
  }

  onBlurFreeText() {
    let tooltips = this.state.options.tooltips;
    tooltips = {
      open: false
    };
    this._modifyOptions('tooltips', tooltips);
  }

  submit() {
    let options = this.prepareOptions(this.state.options);
    if (this.state.isBulk) {
      if (!this.isEmptyCreatives(options)) {
        return;
      }
      if (!this.isEmptyArticle(options)) {
        return;
      }
      if (!this.isEmptyCreator(options)) {
        return;
      }
    }

    if (!this.validateSetup()) {
      return;
    }
    console.log(this.state.options);
    let title = '';
    let text = null;
    if (this.state.options.warnings && this.state.options.warnings.length > 0) {
      const warningBids = this.state.options.warnings;
      let warn = [];
      warningBids.forEach(el => {
        warn.push(this.renderRowName(el));
      });
      warn.push('end');
      title = 'You have high bids:';
      text = warn.map((el, idx) => {
        if (el === 'end') {
          return (
            <p key={idx}>
              <br />
              {'Are you sure to do this?'}
            </p>
          );
        }
        return <p key={idx}>{el}</p>;
      });
    } else {
      title = this.getCreateButtonText();
      text = 'Are you sure to do this?';
    }
    confirmAlert({
      title: title,
      message: text,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.startCreateCampaign()
        },
        {
          label: 'No',
          onClick: () => this.prepareOptions(this.state.options)
        }
      ]
    });
  }

  getSuggestedBid(bid) {
    try {
      const bidDevice = bid.device.length > 0 ? bid.device[0].toLowerCase() : 'unknown';
      const bidSite = bid.site ? bid.site : 'all';
      const bidLanguage =
        bid.country === 'us' && bidDevice === 'm' && this.state.options[GLOBAL.KEY_WIDGET_LANGUAGE_CODE]
          ? this.state.options[GLOBAL.KEY_WIDGET_LANGUAGE_CODE]
          : 'all';
      const bidOS = bid.os === TB.ANDROID ? 'adr' : bid.os === TB.IOS ? 'ios' : 'all';

      const suggestedBids = this.suggestedBidsTaboola || [];

      const filteredBids = suggestedBids.filter(
        x =>
          x.geo === bid.country &&
          x.device === bidDevice &&
          x.site === bidSite &&
          x.language === bidLanguage &&
          x.os === bidOS
      );

      if (filteredBids.length > 0) {
        return Math.max(parseFloat(filteredBids[0].epc), TB.MIN_BID);
      } else {
        return 'unknown';
      }
    } catch (e) {
      console.error(`can't get suggested bid for ${JSON.stringify(bid, null, 2)}`, e);
      Sentry.captureException(e);
      return 'unknown';
    }
  }

  setAllSuggestedBids() {
    const bids = this.state.options[TB.KEY_BIDS];
    bids.forEach(b => {
      const parsedBid = parseFloat(this.getSuggestedBid(b));
      const fixedBid = parseFloat(parsedBid.toFixed(3));
      const smartBid = parseFloat((parsedBid / 2).toFixed(3));
      if (!Number.isNaN(parsedBid)) {
        b.bid = this.state.options[TB.KEY_BID_TYPE] === 'FIXED' ? fixedBid : smartBid;
      }
    });
    this._modifyOptions(TB.KEY_BIDS, bids);
  }

  setAllFlags(key) {
    const bids = this.state.options[TB.KEY_BIDS];
    bids.forEach(b => {
      b[key] = true;
    });
    this._modifyOptions(TB.KEY_BIDS, bids);
  }

  updateDestinationSubAccount(subAcc) {
    const { subAccounts, siteList } = this.props;
    if (subAcc) {
      const selectedSubAccount = subAccounts[subAcc];
      const { site, device: originalDevice } = selectedSubAccount.autoselect[0];
      const device = this.getDeviceNames(originalDevice);
      let siteInList = siteList.some(siteObj => siteObj.value === site);

      if (siteInList) {
        this.updateOption(TB.KEY_SITE, site);
      } else {
        this.modifyOptions(TB.KEY_SITE, []);
      }

      if (siteList.includes(site)) {
        this.updateOption(TB.KEY_SITE, site);
      }

      this.updateOption(TB.KEY_DEVICE, device);
    }
    this.setState({ destinationSubAccount: subAcc, randomizeAccount: false });
  }

  getDeviceNames(deviceSymbol) {
    const deviceNames = {
      d: 'Desktop',
      m: 'Mobile',
      t: 'Tablet'
    };

    if (deviceSymbol) {
      return deviceNames[deviceSymbol];
    } else {
      return undefined;
    }
  }

  getDestinationSubAccountOptions() {
    return Object.values(this.props.subAccounts)
      .map(el => {
        return { label: el.name || el.account_id, value: el.id };
      })
      .filter(el => !TB.OLD_ACCOUNTS.includes(el.value))
      .sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0));
  }

  registerAutoFillCallback(f) {
    this.autoFillWidget = f;
  }

  handleCopyChange(site, newValue) {
    this.setState(prevState => {
      const newSiteCopies = { ...prevState.options.siteCopies, [site]: newValue };
      return { options: { ...prevState.options, siteCopies: newSiteCopies } };
    });
  }

  handleChangeRandomizeAccount(randomizeAccount) {
    if (randomizeAccount && this.state.destinationSubAccount) {
      this.updateDestinationSubAccount();
    }
    this.setState({ randomizeAccount });
  }

  filterPrefixes(prefixes) {
    //Temporary 17.12.2023
    //TODO move logic when we will have more details
    let allowedPrefixes = TB.PREFIX_LIST;
    if (this.props.programOptions.email === 'nofar.h@shinez.io') {
      allowedPrefixes.push('sh');
    }
    if (prefixes.length) {
      return prefixes.filter(prefix => TB.PREFIX_LIST.includes(prefix));
    }
  }

  render() {
    let duplication = this.props.task === TASKS.DUPLICATE;
    let bulk = this.state.isBulk;

    return (
      <>
        {!this.state.isBulk ? (
          <>
            <Grid item xs={12} sm={4}>
              <Typography gutterBottom variant={'subtitle2'}>
                Destination subAccount
              </Typography>
            </Grid>
            <Grid item xs={12} sm={this.props.task === TASKS.DUPLICATE ? 6 : 8}>
              <Select
                onChange={x => this.updateDestinationSubAccount(x?.value)}
                options={this.getDestinationSubAccountOptions()}
                isClearable
                value={getSelectValue(this.getDestinationSubAccountOptions(), this.state.destinationSubAccount)}
              />
            </Grid>
          </>
        ) : (
          <Grid item xs={12} sm={4}></Grid>
        )}
        {duplication || bulk ? (
          <Grid item xs={12} sm={duplication ? 2 : 8} alignItems={'center'}>
            <Tooltip title={'Randomize destination account'}>
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    checked={this.state.randomizeAccount}
                    onChange={e => this.handleChangeRandomizeAccount(e.target.checked)}
                    color="primary"
                  />
                }
                label={
                  duplication ? (
                    <Typography variant="subtitle2">Random</Typography>
                  ) : bulk ? (
                    <Typography variant="subtitle2">Randomize destination subaccount</Typography>
                  ) : null
                }
              />
            </Tooltip>
          </Grid>
        ) : null}

        {this.props.task === TB.DUPLICATE_WITH_DATA_SEGMENTS && (
          <>
            <Grid item xs={12} sm={4}>
              <Typography gutterBottom variant={'subtitle2'}>
                Select metric
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Select
                onChange={x => this.modifyOptions(TB.KEY_METRIC, x.value)}
                options={this.getMetricOptions()}
                value={getSelectValue(this.getMetricOptions(), this.state.options[TB.KEY_METRIC])}
              />
            </Grid>
          </>
        )}
        <>
          <Prefix
            value={{ label: this.state.options[TB.KEY_PREFIX], value: this.state.options[TB.KEY_PREFIX] }}
            onChange={x => this.modifyOptions(TB.KEY_PREFIX, x.value)}
            options={makeSelectOptions(this.filterPrefixes(this.props.programOptions?.prefix))}
          />
          {!this.state.isBulk && (
            <>
              <Grid item xs={12} sm={4}>
                <Typography gutterBottom variant={'subtitle2'}>
                  Creator
                </Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
                <Select
                  options={this.props.creatorsList}
                  onChange={x => this.modifyOptions(TB.KEY_CREATOR, x.value)}
                  value={getSelectValue(this.props.creatorsList, this.state.options[TB.KEY_CREATOR])}
                />
              </Grid>
            </>
          )}
          <Grid item xs={12} sm={4}>
            <Typography gutterBottom variant={'subtitle2'}>
              Targeted geo
            </Typography>
          </Grid>
          <Grid item xs={8} sm={4}>
            <Select
              isMulti
              onChange={xs => this.modifyOptions(TB.KEY_COUNTRY, getOnlyValue(xs))}
              options={this.props.countryList}
              value={getMultiSelectValue(this.props.countryList, this.state.options[TB.KEY_COUNTRY])}
            />
          </Grid>
          <Grid item xs={4} sm={3} align={'center'}>
            <FormControlLabel
              control={
                <Switch
                  checked={this.state.options[TB.KEY_GEO_DUPLICATION_TYPE] !== 'separate'}
                  onChange={(e, value) => this.modifyOptions(TB.KEY_GEO_DUPLICATION_TYPE, value ? 'group' : 'separate')}
                  color="primary"
                />
              }
              label={
                this.state.options[TB.KEY_GEO_DUPLICATION_TYPE] === 'group' ? (
                  <Typography gutterBottom variant={'subtitle2'}>
                    Group
                  </Typography>
                ) : (
                  <Typography gutterBottom variant={'subtitle2'}>
                    Separate
                  </Typography>
                )
              }
              labelPlacement="start"
              style={{ marginLeft: 'auto' }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography gutterBottom variant={'subtitle2'}>
              Select site
            </Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Select
              isMulti
              onChange={xs => this.modifyOptions(TB.KEY_SITE, getOnlyValue(xs))}
              options={this.props.siteList}
              value={getMultiSelectValue(this.props.siteList, this.state.options[TB.KEY_SITE])}
            />
          </Grid>
          {this.state.options[TB.KEY_SITE].map((site, index) => (
            <React.Fragment key={index}>
              <Grid item xs={12} sm={4} key={index}>
                <Typography gutterBottom variant={'subtitle2'}>
                  Number of copies for {site}
                </Typography>
              </Grid>
              <Grid item sm={8}>
                <input
                  min="1"
                  step="1"
                  type="number"
                  value={this.state.options.siteCopies[site]}
                  onChange={e => this.handleCopyChange(site, parseInt(e.target.value))}
                />
              </Grid>
            </React.Fragment>
          ))}
          <Grid item xs={12} sm={4}>
            <Typography gutterBottom variant={'subtitle2'}>
              Select device
            </Typography>
          </Grid>
          <Grid item xs={8} sm={4}>
            <Select
              isMulti
              onChange={xs => this.modifyOptions(TB.KEY_DEVICE, getOnlyValue(xs))}
              options={this.getDevices()}
              value={getMultiSelectValue(this.getDevices(), this.state.options[TB.KEY_DEVICE])}
            />
          </Grid>
          {/* <Grid item xs={6} sm={4}> */}
          <Grid item xs={4} sm={3} align={'center'}>
            <FormControlLabel
              control={
                <Switch
                  checked={this.state.options[TB.KEY_DEVICE_DUPLICATION_TYPE] !== 'separate'}
                  onChange={(e, value) =>
                    this.modifyOptions(TB.KEY_DEVICE_DUPLICATION_TYPE, value ? 'group' : 'separate')
                  }
                  color="primary"
                />
              }
              label={
                this.state.options[TB.KEY_DEVICE_DUPLICATION_TYPE] === 'group' ? (
                  <Typography gutterBottom variant={'subtitle2'}>
                    Group
                  </Typography>
                ) : (
                  <Typography gutterBottom variant={'subtitle2'}>
                    Separate
                  </Typography>
                )
              }
              labelPlacement="start"
              style={{ marginLeft: 'auto' }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography gutterBottom variant={'subtitle2'}>
              Select OS
            </Typography>
          </Grid>
          <Grid item xs={8} sm={4}>
            <Select
              isMulti
              onChange={xs => this.modifyOptions(TB.KEY_OS, getOnlyValue(xs))}
              options={makeSelectOptions(this.state.options[TB.KEY_OS_CHOICES])}
              value={getMultiSelectValue(
                makeSelectOptions(this.state.options[TB.KEY_OS_CHOICES]),
                this.state.options[TB.KEY_OS]
              )}
            />
          </Grid>
          <Grid item xs={4} sm={3} align={'center'}>
            <FormControlLabel
              control={
                <Switch
                  checked={this.state.options[TB.KEY_OS_DUPLICATION_TYPE] !== 'separate'}
                  onChange={(e, value) => this.modifyOptions(TB.KEY_OS_DUPLICATION_TYPE, value ? 'group' : 'separate')}
                  color="primary"
                />
              }
              label={
                this.state.options[TB.KEY_OS_DUPLICATION_TYPE] === 'group' ? (
                  <Typography gutterBottom variant={'subtitle2'}>
                    Group
                  </Typography>
                ) : (
                  <Typography gutterBottom variant={'subtitle2'}>
                    Separate
                  </Typography>
                )
              }
              labelPlacement="start"
              style={{ marginLeft: 'auto' }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography gutterBottom variant={'subtitle2'}>
              Select Browser
            </Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Select
              isMulti
              onChange={xs => this.modifyOptions(TB.KEY_BROWSER, getOnlyValue(xs))}
              options={TB.BROWSERS}
              value={getMultiSelectValue(TB.BROWSERS, this.state.options[TB.KEY_BROWSER])}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography gutterBottom variant={'subtitle2'}>
              Custom Audiences
            </Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Select
              isMulti
              closeMunuOnSelect={false}
              onChange={xs => this.modifyOptions(TB.KEY_CA, getOnlyValue(xs))}
              options={this.state.customAudiences.map(x => ({ label: x.display_name, value: x.id }))}
              value={getMultiSelectValue(
                this.state.customAudiences.map(x => ({ label: x.display_name, value: x.id })),
                this.state.options[TB.KEY_CA]
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography gutterBottom variant={'subtitle2'}>
              Conversion Goal
            </Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Select
              onChange={x => this.modifyOptions(TB.KEY_CONVERSION_ID, x.value)}
              options={this.state.conversionRules.map(x => ({ label: x.display_name, value: x.id }))}
              value={getSelectValue(
                this.state.conversionRules.map(x => ({ label: x.display_name, value: x.id })),
                this.state.options[TB.KEY_CONVERSION_ID]
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography gutterBottom variant={'subtitle2'}>
              Free text
            </Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Tooltip key={'tooltip'} title={this.state.options.tooltips ? this.state.options.tooltips.text : ''}>
              <textarea
                key={'freeText' + this.props.key}
                value={this.state.options.freeText}
                onChange={e => this.onChangeFreeText(e)}
                onBlur={() => this.onBlurFreeText()}
                maxLength={40}
                rows="1"
                style={{
                  resize: 'none'
                }}
              />
            </Tooltip>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography gutterBottom variant={'subtitle2'}>
              Status
            </Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Select
              onChange={x => this.modifyOptions(TB.KEY_STATUS, x.value)}
              options={TB.STATUS}
              value={getSelectValue(TB.STATUS, this.state.options[TB.KEY_STATUS])}
            />
          </Grid>
          {!this.state.isBulk && (
            <>
              <WidgetSelector
                modify={(key, value) => this.modifyOptions(key, value)}
                articleList={this.props.articleList}
                options={this.state.options}
                allowedVersions={this.getAllowedWidgetVersions()}
                defaultVersion={TB.DEFAULT_VERSION}
                triggerSelectorUpdate={this.state.triggerSelectorUpdate}
                registerAutoFillCallback={f => this.registerAutoFillCallback(f)}
                programOptions={this.props.programOptions}
              />
            </>
          )}
          <Grid item xs={12} sm={4}>
            <Typography gutterBottom variant={'subtitle2'}>
              Marketing objective
            </Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Select
              onChange={x => this.modifyOptions(TB.KEY_MARKETING_OBJECTIVE, x.value)}
              options={TB.OBJECTIVES_LIST}
              value={getSelectValue(TB.OBJECTIVES_LIST, this.state.options[TB.KEY_MARKETING_OBJECTIVE])}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <Typography gutterBottom variant={'subtitle2'}>
              Query parameters
            </Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <input
              type="text"
              name={TB.KEY_QUERY_PARAMETERS}
              value={this.state.options[TB.KEY_QUERY_PARAMETERS]}
              onChange={el => this.modifyOptions(TB.KEY_QUERY_PARAMETERS, el.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography gutterBottom variant={'subtitle2'}>
              Budget types
            </Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Select
              onChange={x => this.modifyOptions(TB.KEY_SPENDING_LIMIT_MODEL, x.value)}
              options={TB.SPENDING_MODEL_LIST}
              value={getSelectValue(TB.SPENDING_MODEL_LIST, this.state.options[TB.KEY_SPENDING_LIMIT_MODEL])}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography gutterBottom variant={'subtitle2'}>
              Budget
            </Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <input
              type="number"
              name={TB.KEY_BUDGET}
              value={this.state.options[TB.KEY_BUDGET]}
              onChange={el => this.modifyOptions(TB.KEY_BUDGET, el.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={12} align="center">
            <Typography gutterBottom variant={'subtitle2'}>
              Bids
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} align="center">
            <Divider />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography gutterBottom variant={'subtitle2'}>
              Bid strategy
            </Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Select
              onChange={x => this.modifyOptions(TB.KEY_BID_STRATEGY, x.value)}
              options={TB.BID_STRATEGY_LIST}
              value={getSelectValue(TB.BID_STRATEGY_LIST, this.state.options[TB.KEY_BID_STRATEGY])}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <Typography gutterBottom variant={'subtitle2'}>
              Default bid budget
            </Typography>
          </Grid>
          <Grid item xs={12} sm={3}>
            <input
              type="number"
              name={TB.KEY_DEFAULT_BID}
              step={TB.BID_STEP}
              value={this.state.options[TB.KEY_DEFAULT_BID]}
              onChange={el => this.modifyOptions(TB.KEY_DEFAULT_BID, el.target.value)}
              onBlur={() => this.validateOptions(TB.KEY_DEFAULT_BID)}
            />
          </Grid>
          <Grid item xs={12} sm={5}>
            <Button variant="outlined" fullWidth color="primary" onClick={() => this.setAllSuggestedBids()}>
              Set all suggested bids
            </Button>
          </Grid>
          <TaboolaBids
            bids={this.state.options[TB.KEY_BIDS]}
            modifyBid={(idx, e) => this.modifyBid(idx, e)}
            validateBid={idx => this.validateBid(idx)}
            getSuggestedBid={b => this.getSuggestedBid(b)}
            conversionRules={this.state.conversionRules}
            customAudience={this.state.options[TB.KEY_CA]}
            modifyConversionRule={(idx, e) => this.modifyConversionRule(idx, e)}
          />
          <Grid item xs={12} sm={4}>
            <Typography gutterBottom variant={'subtitle2'}>
              Times
            </Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <input
              min="1"
              type="number"
              name="campaignCopy"
              value={this.state.options[TB.KEY_TIMES]}
              onChange={e => {
                const value = Math.floor(parseFloat(e.target.value));
                this.modifyOptions(TB.KEY_TIMES, value);
              }}
            />
          </Grid>
          <CampaignsNote
            value={this.state.options[GLOBAL.KEY_CAMPAIGN_NOTE]}
            onChange={e => {
              this._modifyOptions(GLOBAL.KEY_CAMPAIGN_NOTE, e.target.value);
            }}
          />
          {!this.state.isBulk && (
            <>
              <Grid item xs={12} sm={4}>
                <Typography gutterBottom variant={'subtitle2'}>
                  Creative Groups:
                </Typography>
              </Grid>
              <Grid item xs={6} sm={4} align="right" />
              <Grid item xs={6} sm={4} align="right">
                <Button fullWidth variant="contained" color="primary" onClick={() => this.addCreativeGroup()}>
                  Add group
                </Button>
              </Grid>
              <TaboolaCreativeGroupBuilder
                groups={this.state.options[TB.KEY_CREATIVE_GROUPS]}
                onAddImages={(idx, e) => this.onAddImages(idx, e)}
                onChangeImageText={(groupIdx, imageIdx, e) => this.onChangeImageText(groupIdx, imageIdx, e)}
                onChangeImageDescription={(groupIdx, imageIdx, e) =>
                  this.onChangeImageDescription(groupIdx, imageIdx, e)
                }
                onChangeImageCTA={(groupIdx, imageIdx, e) => this.onChangeImageCTA(groupIdx, imageIdx, e)}
                onPasteImageText={(groupIdx, imageIdx, e) => this.onPasteImageText(groupIdx, imageIdx, e)}
                removeImage={(groupIdx, imageIdx) => this.removeImage(groupIdx, imageIdx)}
                duplicateImage={(groupIdx, imageIdx) => this.duplicateImage(groupIdx, imageIdx)}
                removeGroup={idx => this.removeGroup(idx)}
                duplicateGroup={idx => this.duplicateGroup(idx)}
                populateText={(groupIdx, imageIdx) => this.populateText(groupIdx, imageIdx)}
              />
            </>
          )}
        </>
        <>
          <Grid item xs={12} sm={4} align="right" />
          <Grid item xs={6} sm={4} align="right">
            <Button variant="outlined" fullWidth color="primary" onClick={() => this.upsertSnapshot()}>
              {this.props.loadedFromSnapshot ? 'Update' : 'Save'} snapshot
            </Button>
          </Grid>
          <Grid item xs={6} sm={4} align="right">
            <Button variant="contained" fullWidth color="primary" onClick={() => this.submit()}>
              {this.getCreateButtonText()}
            </Button>
          </Grid>
        </>
      </>
    );
  }
}

function formCreativeGroup(itemData) {
  const group = {
    images: [],
    texts: [],
    descriptions: [],
    cta: []
  };
  itemData.forEach(item => {
    group.images.push(item.image);
    group.texts.push(item.title);
    group.descriptions.push(item.text);
    group.cta.push(item.cta);
  });
  return group;
}

function parseDuplicateData(options, sourceData, articleList, platform, creatorsList) {
  try {
    let { campaign } = sourceData.account;
    options[TB.KEY_CREATIVE_GROUPS] = [formCreativeGroup(campaign.item)];
    options[TB.KEY_BIDS] = [];
    options[TB.KEY_SPENDING_LIMIT_MODEL] = campaign.spending_limit_model;
    options[TB.KEY_MARKETING_OBJECTIVE] = campaign.marketing_objective;
    options[TB.KEY_BID_STRATEGY] = campaign.bid_strategy || 'SMART';
    options.campaignName = campaign._name;
    const parsedName = parseCampaignName(platform, campaign._name);
    const clv = articleCodeLanguageVersion(articleList, campaign.widgetid, campaign.article, TB.DEFAULT_VERSION[0]);
    options[GLOBAL.KEY_WIDGET_CODE_NAME] = clv[0];
    options[GLOBAL.KEY_WIDGET_LANGUAGE_CODE] = clv[1];
    options[GLOBAL.KEY_WIDGET_VERSION] = clv[2];
    if (campaign.note) {
      options[GLOBAL.KEY_CAMPAIGN_NOTE] = campaign.note;
    }
    options[TB.KEY_SITE] = [campaign.site];
    options[TB.KEY_COUNTRY] = [campaign.country.toLowerCase()];
    options[TB.KEY_CREATOR] = getNewCreatorValue(campaign.creator, creatorsList);

    options[TB.KEY_OS_CHOICES] = [];
    options[TB.KEY_DEVICE] = [];
    if (parsedName.platform === 'd') {
      options[TB.KEY_DEVICE].push(TB.DESKTOP);
    } else if (parsedName.platform === 'a') {
      options[TB.KEY_DEVICE].push(TB.MOBILE, TB.TABLET);
      options[TB.KEY_OS_CHOICES] = TB_OS[TB.MOBILE];
    } else {
      if (campaign.device === TB.API_MOBILE) {
        options[TB.KEY_OS_CHOICES] = TB_OS[TB.MOBILE];
        options[TB.KEY_DEVICE].push(TB.MOBILE);
      }
      if (campaign.device === TB.API_TABLET) {
        options[TB.KEY_OS_CHOICES] = TB_OS[TB.TABLET];
        options[TB.KEY_DEVICE].push(TB.TABLET);
      }
    }
    if (campaign.os) {
      options[TB.KEY_OS] = [];
      if (campaign.os.includes(TB.IOS)) {
        options[TB.KEY_OS].push(TB.IOS);
      } else if (campaign.os.includes(TB.ANDROID)) {
        options[TB.KEY_OS].push(TB.ANDROID);
      } else {
        options[TB.KEY_OS].push(TB.ALL);
      }
    }
    options[TB.KEY_BROWSER] = [];
    if (campaign.browser) {
      options[TB.KEY_BROWSER] = campaign.browser.length > 0 ? campaign.browser.split(',') : ['ALL'];
    }

    if (campaign.custom_audience) {
      options[TB.KEY_CA] = campaign.custom_audience;
    }

    console.debug('Setting options');
    console.debug(JSON.stringify(options));
    // We save the original source data to compare it before sending the duplication request to server
    // If only some of the "safe" fields are changed, then we can use TB duplication API
    // Otherwise we can only create a new campaign and update the fields in it, like we do for every other platform (as of 06.2020)
    const sourceOptions = JSON.parse(JSON.stringify(options));
    // Save only original creative group, as only this one can be preserved using TB duplication API
    sourceOptions[TB.KEY_CREATIVE_GROUPS] = [JSON.parse(JSON.stringify(options[TB.KEY_CREATIVE_GROUPS]))[0]];
    options[TB.KEY_ORIGINAL_SOURCE_DATA] = sourceOptions;
    return options;
  } catch (e) {
    toast.error(`can't parse source data ${e}`, {
      autoClose: false
    });
    console.error(`can't parse source data`, e);
    return options;
  }
}

export { TaboolaCreate };
