import { TB, RC, PLATFORMS } from './Constants';

export function parseCampaignName(platform, name) {
  switch (platform) {
    case PLATFORMS.BAIDU:
      return parseBaiduCampaignName(name);
    case PLATFORMS.TABOOLA:
      return parseTaboolaCampaignName(name);
    case PLATFORMS.REVCONTENT:
      return parseRevcontentCampaignName(name);
    case PLATFORMS.OUTBRAIN:
      return parseOutbrainCampaignName(name);
    case PLATFORMS.TWITTER:
      return parseTwitterCampaignName(name);
    case PLATFORMS.FACEBOOK:
      return parseFacebookCampaignName(name);
    case PLATFORMS.GOOGLE_ADS:
      return parseGoogleAdsCampaignName(name);
    case PLATFORMS.ZEMANTA:
      return parseZemantaCampaignName(name);
    default:
      throw new Error('Unknown platform ' + platform);
  }
}

function parseTaboolaCampaignName(name) {
  let splitted = name.split('-');
  const msnIdx = splitted.indexOf(TB.MSN_SAFE);
  const newMsnIdx = splitted.indexOf(TB.MSNSAFE);
  if (msnIdx !== -1) {
    // Remove MSN_SAFE prefix/suffix
    splitted.splice(msnIdx, 1);
  }
  if (newMsnIdx !== -1) {
    // Remove MSN_SAFE prefix/suffix
    splitted.splice(newMsnIdx, 1);
  }
  const widgetCodeName = typeof splitted[4] === 'string' ? splitted[4].substring(0, 5) : '';
  const widgetLanguageCode = typeof splitted[4] === 'string' ? splitted[4].substring(5, 7) : '';
  return {
    prefix: splitted[0],
    country: splitted[1],
    platform: splitted[2],
    site: splitted[3],
    article: splitted[4],
    widgetCodeName: widgetCodeName,
    widgetLanguageCode: widgetLanguageCode,
    account: splitted[5]
  };
}

function parseRevcontentCampaignName(name) {
  let splitted = name.split('-');
  const msnIdx = splitted.indexOf(RC.MSN_SAFE);
  if (msnIdx !== -1) {
    // Remove MSN_SAFE prefix/suffix
    splitted.splice(msnIdx, 1);
  }
  const widgetCodeName = typeof splitted[4] === 'string' ? splitted[4].substring(0, 5) : '';
  const widgetLanguageCode = typeof splitted[4] === 'string' ? splitted[4].substring(5, 7) : '';
  return {
    prefix: splitted[0],
    country: splitted[1],
    platform: splitted[2],
    site: splitted[3],
    article: splitted[4],
    widgetCodeName: widgetCodeName,
    widgetLanguageCode: widgetLanguageCode,
    os: splitted[5]
  };
}

function parseBaiduCampaignName(name) {
  let splitted = name.split('-');
  const widgetCodeName = typeof splitted[4] === 'string' ? splitted[4].substring(0, 5) : '';
  const widgetLanguageCode = typeof splitted[4] === 'string' ? splitted[4].substring(5, 7) : '';
  return {
    prefix: splitted[0],
    country: splitted[1],
    platform: splitted[2],
    site: splitted[3],
    article: splitted[4],
    widgetCodeName: widgetCodeName,
    widgetLanguageCode: widgetLanguageCode
  };
}

function parseOutbrainCampaignName(name) {
  let parsed = {};
  const splitted = name.split('-');
  parsed.prefix = splitted[0];
  parsed.country = splitted[1];
  parsed.device = splitted[2];
  parsed.site = splitted[3];
  parsed.article = splitted[4];
  const widgetCodeName = typeof splitted[4] === 'string' ? splitted[4].substring(0, 5) : '';
  const widgetLanguageCode = typeof splitted[4] === 'string' ? splitted[4].substring(5, 7) : '';
  parsed.widgetCodeName = widgetCodeName;
  parsed.widgetLanguageCode = widgetLanguageCode;
  parsed.os = ['adr', 'ios'].indexOf(splitted[5]) !== -1 ? splitted[5] : null;
  // Date is added after article or os, but os may be absent
  parsed.date = ['adr', 'ios'].indexOf(splitted[5]) === -1 ? splitted[5] : splitted[6];
  return parsed;
}

function parseZemantaCampaignName(name) {
  let parsed = {};
  const splitted = name.split('-');
  parsed.prefix = splitted[0];
  parsed.country = splitted[1];
  parsed.device = splitted[2];
  parsed.site = splitted[3];
  parsed.article = splitted[4];
  const widgetCodeName = typeof splitted[4] === 'string' ? splitted[4].substring(0, 5) : '';
  const widgetLanguageCode = typeof splitted[4] === 'string' ? splitted[4].substring(5, 7) : '';
  parsed.widgetCodeName = widgetCodeName;
  parsed.widgetLanguageCode = widgetLanguageCode;
  parsed.os = ['adr', 'ios'].indexOf(splitted[5]) !== -1 ? splitted[5] : null;
  parsed.date = ['adr', 'ios'].indexOf(splitted[5]) === -1 ? splitted[5] : splitted[6];
  return parsed;
}

function parseTwitterCampaignName(name) {
  //xx-us-d-trm-100yeen-trm-c966
  let parsed = {};
  const splitted = name.split('-');
  parsed.prefix = splitted[0];
  parsed.country = splitted[1];
  parsed.device = splitted[2];
  parsed.site = splitted[3];
  parsed.article = splitted[4];
  const widgetCodeName = typeof splitted[4] === 'string' ? splitted[4].substring(0, 5) : '';
  const widgetLanguageCode = typeof splitted[4] === 'string' ? splitted[4].substring(5, 7) : '';
  parsed.widgetCodeName = widgetCodeName;
  parsed.widgetLanguageCode = widgetLanguageCode;
  parsed.account = splitted[5];
  return parsed;
}

function parseFacebookCampaignName(name) {
  // Template: fb-us-m-omg-article-g1-c2
  const elements = name.split('-');
  if (elements.length >= 7) {
    const article = elements[4];
    return {
      prefix: elements[0],
      country: elements[1],
      platform: elements[2],
      domain: elements[3],
      article,
      widgetCodeName: article.substring(0, 5),
      widgetLanguageCode: article.substring(5, 7),
      accountPrefix: elements[5].substring(0, elements[5].search(/\d/)),
      account: Number(elements[5].substring(elements[5].search(/\d/))),
      fullAccountName: elements[5],
      campaign: Number(elements[6].substring(elements[6].search(/\d/))),
      campaignName: elements.slice(0, 7).join('-')
    };
  }
}

function parseGoogleAdsCampaignName(name) {
  //ug-us-m-hpt-khloeen-ghpt1-c1
  let splitted = name.split('-');
  const widgetCodeName = typeof splitted[4] === 'string' ? splitted[4].substring(0, 5) : '';
  const widgetLanguageCode = typeof splitted[4] === 'string' ? splitted[4].substring(5, 7) : '';
  return {
    prefix: splitted[0],
    country: splitted[1],
    platform: splitted[2],
    site: splitted[3],
    article: splitted[4],
    widgetCodeName: widgetCodeName,
    widgetLanguageCode: widgetLanguageCode
  };
}
